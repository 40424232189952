<template>
  <el-form
    ref="ruleFormRef"
    :model="state.ruleForm"
    :rules="rules"
    label-width="100px"
    class="demo-ruleForm"
    :size="formSize"
    status-icon>
    <el-form-item label="来文编号" prop="file_no">
      <el-input v-model="state.ruleForm.file_no" :disabled="state.disabled" />
    </el-form-item>

    <el-form-item label="来文字号" prop="file_code">
      <el-input v-model="state.ruleForm.file_code" :disabled="state.disabled" />
    </el-form-item>

    <el-form-item label="来文单位" prop="department">
      <el-input v-model="state.ruleForm.department" :disabled="state.disabled" />
    </el-form-item>

    <el-form-item label="紧急程度" prop="emergency">
      <el-select v-model="state.ruleForm.emergency" :disabled="state.disabled" placeholder="请选择紧急程度">
        <el-option label="一般" value="一般">
          <el-tag type="info">一般</el-tag>
        </el-option>

        <el-option label="急" value="急">
          <el-tag type="warning">急</el-tag>
        </el-option>

        <el-option label="特急" value="特急">
          <el-tag type="danger">特急</el-tag>
          <span
            style="float: right; color: var(--el-text-color-secondary);font-size: 13px;">
            最高权重
          </span>
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="收文日期">
      <el-form-item prop="received_date">
        <el-date-picker
          v-model="state.ruleForm.received_date"
          type="date"
          value-format="YYYY-MM-DD"
          placeholder="选择收文日期"
          :disabled="state.disabled"
        />
      </el-form-item>
    </el-form-item>

    <!-- <el-form-item label="转办时间" prop="transfer_date">
      <el-date-picker
          v-model="state.ruleForm.transfer_date"
          type="datetime"
          value-format="YYYY-MM-DD HH:mm:ss"
          placeholder="选择转办时间"
          :disabled="state.disabled"
        />
    </el-form-item> -->

    <el-form-item label="文件标题" prop="title">
      <el-input v-model="state.ruleForm.title" :disabled="state.approvalStatus === '未完成'" />
    </el-form-item>

    <el-form-item label="批办人" prop="operator">
      <el-input v-model="state.ruleForm.operator" :disabled="state.approvalStatus === '已完成'" />
    </el-form-item>

    <el-form-item label="批办意见" prop="opinion">
      <el-input v-model="state.ruleForm.opinion" :disabled="state.approvalStatus === '已完成'" type="textarea" />
    </el-form-item>

    <el-form-item label="批办日期">
      <el-form-item prop="operate_date">
        <el-date-picker
          v-model="state.ruleForm.operate_date"
          type="date"
          value-format="YYYY-MM-DD"
          placeholder="选择批办日期"
          style="width: 100%"
          :disabled="state.disabled"
        />
      </el-form-item>
    </el-form-item>

    <el-form-item label="文件来源" prop="origin">
      <el-radio-group v-model="state.ruleForm.origin" :disabled="state.disabled">
        <el-radio label="国家" />
        <el-radio label="自治区" />
        <el-radio label="锡林郭勒盟" />
        <el-radio label="二连浩特市" />
      </el-radio-group>
    </el-form-item>

    <el-form-item>
      <el-button @click="handleEditPreview()">预览</el-button>
      <el-button type="primary" @click="submitForm(ruleFormRef)" :loading="state.loading">
        {{state.loading ? '保存中...' : '保存'}}
      </el-button>
    </el-form-item>
  </el-form>

  <EditPreview ref="editPreviewRef"></EditPreview>
  </template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { fileUpdate } from '@/api'
import EditPreview from '@/components/EditPreview.vue'
import { getSession } from '@/utils'
import { useRouter } from 'vue-router'
const router = useRouter()
const editPreviewRef = ref(null)
const editData = getSession('editData')
console.log('拿到了啥', editData)

const formSize = ref('default')
const ruleFormRef = ref()
const state = reactive({
  disabled: !!editData?.disabled,
  approvalStatus: editData?.approval_status,
  ruleForm: {
    id: editData?.id || '',
    file_no: editData?.file_no || '', // 来文编号
    file_code: editData?.file_code || '', // 来文字号
    department: editData?.department || '', // 来文单位
    emergency: editData?.emergency || '', // 紧急程度
    received_date: editData?.received_date || '', // 收文日期
    transfer_date: editData?.transfer_date || '', // 转办时间
    title: editData?.title || '', // 文件标题
    operator: editData?.operator || '', // 批办人
    opinion: editData?.opinion || '', // 批办意见
    operate_date: editData?.operate_date || '', // 批办日期
    origin: editData?.origin || '', // 文件来源
    merge_sign: editData?.merge_sign || '' // 处理中、已撤回的标识字段
  },
  loading: false
})

const rules = {
  // file_no: [
  //   { required: true, message: '来文编号不能为空', trigger: 'blur' }
  // ],
  // file_code: [
  //   { required: true, message: '来文字号不能为空', trigger: 'blur' }
  // ],
  // department: [
  //   { required: true, message: '来文单位不能为空', trigger: 'blur' }
  // ],
  // emergency: [
  //   { required: true, message: '紧急程度不能为空', trigger: 'change' }
  // ],
  // received_date: [
  //   {
  //     type: 'date',
  //     required: true,
  //     message: '请选择收文日期',
  //     trigger: 'change'
  //   }
  // ],
  // transfer_date: [
  //   { required: true, message: '转办时间不能为空', trigger: 'blur' }
  // ],
  // title: [
  //   { required: true, message: '标题不能为空', trigger: 'blur' }
  // ],
  // operator: [
  //   { required: true, message: '批办人不能为空', trigger: 'blur' }
  // ],
  // opinion: [
  //   { required: true, message: '批办意见不能为空', trigger: 'blur' }
  // ],
  // operate_date: [
  //   {
  //     type: 'date',
  //     required: true,
  //     message: '请选择批办日期',
  //     trigger: 'change'
  //   }
  // ],
  origin: [
    {
      required: true,
      message: '请选择文件来源',
      trigger: 'change'
    }
  ]
}

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      state.loading = true
      console.log('submit!', state.ruleForm)
      fileUpdate(state.ruleForm).then(res => {
        console.log('文件编辑成功', res)
        state.loading = false
        ElMessage({
          type: 'success',
          message: '编辑成功'
        })
        router.push({
          path: '/signFile'
        })
      }).catch(err => {
        state.loading = false
        ElMessage({
          type: 'error',
          message: err.toString()
        })
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 预览
function handleEditPreview () {
  console.log('预览')
  editPreviewRef.value.show(state.ruleForm)
}
</script>
