<template>
  <div class="edit-preview"
    v-if="state.showEditPreview"
    @click.stop="handleClose">
    <el-scrollbar height="98%" style="margin-top: 1%;">
      <div class="preview-main" @click.stop v-if="state.previewData">
        <div class="main-wrap">
          <div class="row1">
            <div class="left"></div>
            <div class="right"></div>
          </div>
          <div class="row2"></div>
          <div class="row3">
            <div class="left"></div>
            <div class="right flex-center"> <!-- 拟稿 -->
              {{ state.previewData.draft }}
            </div>
          </div>
          <div class="row4">
            <div class="left flex-center"> <!-- 文件标题 -->
              {{ state.previewData.title }}
            </div>
          </div>
          <div class="row5">
            <div class="left flex-center"> <!-- 主送 -->
              {{ state.previewData.main_receiver }}
            </div>
          </div>
          <div class="row6">
            <div class="left flex-center"> <!-- 抄送 -->
              {{ state.previewData.cc_receiver }}
            </div>
          </div>
          <div class="row7">
            <div class="left flex-center"> <!-- 附件 -->
              {{ state.previewData.attr }}
            </div>
          </div>
          <div class="row8">
            <div class="left flex-center">{{ state.previewData.second_policy }}</div> <!-- 二政 -->
            <div class="center">{{ state.previewData.code }}</div> <!-- 号 -->
            <div class="right"> <!-- 公开类型 -->
              <el-icon
                v-if="state.previewData.public_type"
                :class="['check', `op${state.previewData.public_type}`]"
                color="#b32370">
                <Select />
              </el-icon>
            </div>
          </div>
          <div class="row9">
            <div class="year">{{ state.previewData.doc_date.split('-')[0] }}</div>
            <div class="moth">{{ state.previewData.doc_date.split('-')[1] }}</div>
            <div class="day">{{ state.previewData.doc_date.split('-')[2] }}</div>
          </div>
        </div>
        <div class="icon-close" @click.stop="handleClose">
          <el-icon :size="40"><Close /></el-icon>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup>
import { reactive, defineExpose } from 'vue'

const state = reactive({
  showEditPreview: false,
  previewData: null
})

function show (item) {
  state.showEditPreview = true
  state.previewData = item
  console.log('什么情况', state.previewData)
}

function handleClose () {
  state.showEditPreview = false
}

defineExpose({
  show
})
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins.scss";
.edit-preview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: .4);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  .preview-main {
    width: 620px;
    height: 876px;
    box-sizing: border-box;
    padding: 116px 12px 69px 10px;
    background: url('~@/assets/images/edit_preview_bg2.jpg') no-repeat top left;
    background-size: 100%;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    .main-wrap {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 4px;
      // background: rgba($color: #000000, $alpha: .4);
      .row1 {
        width: 518px;
        height: 98px;
        margin-left: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background: rgba($color: red, $alpha: .4);
        .left {
          width: 298px;
          height: 100%;
          // background: rgba($color: orange, $alpha: .4);
        }
        .right {
          width: 133px;
          height: 100%;
          // background: rgba($color: orange, $alpha: .4);
        }
      }
      .row2 {
        width: 518px;
        height: 85px;
        margin-left: 70px;
        margin-top: 9px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background: rgba($color: red, $alpha: .4);
      }
      .row3 {
        width: 518px;
        height: 89px;
        margin-top: 11px;
        margin-left: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background: rgba($color: red, $alpha: .4);
        .left {
          width: 265px;
          height: 100%;
          // background: rgba($color: orange, $alpha: .4);
        }
        .right {
          width: 158px;
          height: 100%;
          // background: rgba($color: orange, $alpha: .4);
        }
      }
      .row4 {
        width: 518px;
        height: 85px;
        margin-left: 70px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background: rgba($color: red, $alpha: .4);
        .left {
          width: 100%;
          height: 100%;
          // background: rgba($color: orange, $alpha: .4);
        }
      }
      .row5 {
        width: 518px;
        height: 57px;
        margin-left: 70px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background: rgba($color: red, $alpha: .4);
        .left {
          width: 100%;
          height: 100%;
          // background: rgba($color: orange, $alpha: .4);
        }
      }
      .row6 {
        width: 518px;
        height: 50px;
        margin-left: 70px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background: rgba($color: red, $alpha: .4);
        .left {
          width: 100%;
          height: 100%;
          // background: rgba($color: orange, $alpha: .4);
        }
      }
      .row7 {
        width: 518px;
        height: 50px;
        margin-left: 70px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background: rgba($color: red, $alpha: .4);
        .left {
          width: 100%;
          height: 100%;
          // background: rgba($color: orange, $alpha: .4);
        }
      }
      .row8 {
        width: 551px;
        height: 50px;
        margin-left: 37px;
        margin-top: 12px;
        display: flex;
        align-items: center;
        // background: rgba($color: red, $alpha: .4);
        .left {
          width: 46px;
          height: 100%;
          margin-right: 9px;
          // background: rgba($color: orange, $alpha: .4);
        }
        .center {
          width: 90px;
          height: 100%;
          margin-right: 60px;
          display: flex;
          align-items: center;
          word-break: break-all;
          white-space: pre-wrap;
          // background: rgba($color: orange, $alpha: .4);
        }
        .right {
          width: 290px;
          height: 12px;
          position: relative;
          // background: rgba($color: orange, $alpha: .1);
          .check {
            position: absolute;
            &.op1 {
              left: 61px;
            }
            &.op2 {
              left: 166px;
            }
            &.op3 {
              left: 269px;
            }
          }
        }
      }
      .row9 {
        width: 518px;
        height: 18px;
        margin-left: 70px;
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // background: rgba($color: red, $alpha: .4);
        .year {
          width: 40px;
          margin-right: 16px;
          position: relative;
          text-align: center;
          // background: rgba($color: orange, $alpha: .4);
        }
        .moth {
          width: 18px;
          margin-right: 14px;
          position: relative;
          text-align: center;
          // background: rgba($color: orange, $alpha: .4);
        }
        .day {
          width: 20px;
          margin-right: 40px;
          position: relative;
          text-align: center;
          // background: rgba($color: orange, $alpha: .4);
        }
      }
    }
    .icon-close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 100;
      cursor: pointer;
    }
  }
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-all;
  }
}
</style>
