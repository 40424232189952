<template>
<div class="sign-file_edit">
  <TemplateOne v-if="editData.template_id === 1" />
  <TemplateTwo v-if="editData.template_id === 2" />
  <TemplateFive v-if="editData.template_id === 5" />
</div>
</template>

<script setup>
// import { reactive } from 'vue'
import { getSession } from '@/utils'
import TemplateOne from './TemplateOne.vue'
import TemplateTwo from './TemplateTwo.vue'
import TemplateFive from './TemplateFive.vue'
const editData = getSession('editData')
console.log('拿到了啥', editData)
</script>

<style lang="scss">
.sign-file_edit {
  width: 500px;
}
</style>
