<template>
  <el-form
    ref="ruleFormRef"
    :model="state.ruleForm"
    :rules="rules"
    label-width="100px"
    class="demo-ruleForm"
    :size="formSize"
    status-icon>
    <el-form-item label="来文机关" prop="department">
      <el-input v-model="state.ruleForm.department" :disabled="state.disabled" />
    </el-form-item>

    <el-form-item label="收文日期" prop="doc_date">
      <el-date-picker
        v-model="state.ruleForm.doc_date"
        type="date"
        value-format="YYYY-MM-DD"
        placeholder="选择收文日期"
        :disabled="state.disabled"
      />
    </el-form-item>

    <el-form-item label="收文文号" prop="file_code">
      <el-input v-model="state.ruleForm.file_code" :disabled="state.disabled" />
    </el-form-item>

    <el-form-item label="收文编号" prop="code">
      <el-input v-model="state.ruleForm.code" :disabled="state.disabled" />
    </el-form-item>

    <el-form-item label="来文名称" prop="title">
      <el-input v-model="state.ruleForm.title" :disabled="state.approvalStatus === '未完成'" />
    </el-form-item>

    <el-form-item label="拟办意见" prop="suggestion">
      <el-input v-model="state.ruleForm.suggestion" :disabled="state.approvalStatus === '已完成'" type="textarea" />
    </el-form-item>

    <el-form-item label="文件来源" prop="origin">
      <el-radio-group v-model="state.ruleForm.origin" :disabled="state.disabled">
        <el-radio label="国家" />
        <el-radio label="自治区" />
        <el-radio label="锡林郭勒盟" />
        <el-radio label="二连浩特市" />
      </el-radio-group>
    </el-form-item>

    <el-form-item>
      <el-button @click="handleEditPreviewFive()">预览</el-button>
      <el-button type="primary" @click="submitForm(ruleFormRef)" :loading="state.loading">
        {{state.loading ? '保存中...' : '保存'}}
      </el-button>
    </el-form-item>
  </el-form>

  <EditPreviewFive ref="editPreviewRef"></EditPreviewFive>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { fileTemplate5Update } from '@/api'
import EditPreviewFive from '@/components/EditPreviewFive.vue'
import { getSession } from '@/utils'
import { useRouter } from 'vue-router'
const router = useRouter()
const editPreviewRef = ref(null)
const editData = getSession('editData')
console.log('拿到了啥', editData)

const formSize = ref('default')
const ruleFormRef = ref()
const state = reactive({
  disabled: !!editData?.disabled,
  approvalStatus: editData?.approval_status,
  ruleForm: {
    id: editData?.id || '',
    department: editData?.department || '来文机关', // 来文机关
    doc_date: editData?.doc_date || '2024-07-17', // 收文日期
    file_code: editData?.file_code || '收文文号', // 收文文号
    code: editData?.code || '收文编号', // 收文编号
    title: editData?.title || '来文名称', // 来文名称
    suggestion: editData?.suggestion || '拟办意见拟办意见拟办意见', // 拟办意见
    origin: editData?.origin || '', // 文件来源
    merge_sign: editData?.merge_sign || '' // 处理中、已撤回的标识字段
  },
  loading: false
})

const rules = {
  origin: [
    {
      required: true,
      message: '请选择文件来源',
      trigger: 'change'
    }
  ]
}

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      state.loading = true
      console.log('submit!', state.ruleForm)
      fileTemplate5Update(state.ruleForm).then(res => {
        console.log('文件编辑成功', res)
        state.loading = false
        ElMessage({
          type: 'success',
          message: '编辑成功'
        })
        router.push({
          path: '/signFile'
        })
      }).catch(err => {
        state.loading = false
        ElMessage({
          type: 'error',
          message: err.toString()
        })
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 预览
function handleEditPreviewFive () {
  console.log('预览')
  editPreviewRef.value.show(state.ruleForm)
}
</script>
