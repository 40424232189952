<template>
  <el-form
    ref="ruleFormRef"
    :model="state.ruleForm"
    :rules="rules"
    label-width="100px"
    class="demo-ruleForm"
    :size="formSize"
    status-icon>
    <el-form-item label="拟稿" prop="draft">
      <el-input v-model="state.ruleForm.draft" :disabled="state.disabled" maxlength="70" />
    </el-form-item>

    <el-form-item label="文件标题" prop="title">
      <el-input v-model="state.ruleForm.title" :disabled="state.disabled" maxlength="260" />
    </el-form-item>

    <el-form-item label="主送">
      <el-input v-model="state.ruleForm.main_receiver" :disabled="state.disabled" maxlength="156" />
    </el-form-item>

    <el-form-item label="抄送">
      <el-input v-model="state.ruleForm.cc_receiver" :disabled="state.disabled" maxlength="156" />
    </el-form-item>

    <el-form-item label="二政">
      <el-input v-model="state.ruleForm.second_policy" :disabled="state.disabled" maxlength="6" />
    </el-form-item>

    <el-form-item label="号">
      <el-input v-model="state.ruleForm.code" :disabled="state.disabled" />
    </el-form-item>

    <el-form-item label="公开类型" prop="public_type">
      <el-radio-group v-model="state.ruleForm.public_type">
        <el-radio label="1">主动公开</el-radio>
        <el-radio label="2">依申请公开</el-radio>
        <el-radio label="3">不予公开</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="日期">
      <el-form-item prop="doc_date">
        <el-date-picker
          v-model="state.ruleForm.doc_date"
          type="date"
          value-format="YYYY-MM-DD"
          placeholder="选择日期"
          :disabled="state.disabled"
        />
      </el-form-item>
    </el-form-item>

    <el-form-item label="附件" prop="attr">
      <el-input v-model="state.ruleForm.attr" :disabled="state.approvalStatus === '已完成'" maxlength="156" />
    </el-form-item>

    <el-form-item>
      <el-button @click="handleEditPreview()">预览</el-button>
      <el-button type="primary" @click="submitForm(ruleFormRef)" :loading="state.loading">
        {{state.loading ? '保存中...' : '保存'}}
      </el-button>
    </el-form-item>
  </el-form>

  <EditPreviewTwo ref="editPreviewTwoRef"></EditPreviewTwo>
  </template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { fileOtherUpdate } from '@/api'
import EditPreviewTwo from '@/components/EditPreviewTwo.vue'
import { getSession } from '@/utils'
import { useRouter } from 'vue-router'
const router = useRouter()
const editPreviewTwoRef = ref(null)
const editData = getSession('editData')
console.log('拿到了啥', editData)

const formSize = ref('default')
const ruleFormRef = ref()
const state = reactive({
  disabled: !!editData?.disabled,
  approvalStatus: editData?.approval_status,
  ruleForm: {
    id: editData?.id || '',
    title: editData?.title || '', // 文件标题
    draft: '', // 拟稿
    main_receiver: '', // 主送
    cc_receiver: '', // 抄送
    second_policy: '', // 二政
    code: '', // 号
    public_type: '', // '公开类型 1 主动公开，2 依申请公开，3 不予公开'
    doc_date: '', // 年/月/日
    attr: '' // 附件
  },
  loading: false
})

const rules = {
  draft: [
    { required: true, message: '拟稿不能为空', trigger: 'blur' }
  ],
  title: [
    { required: true, message: '文件标题不能为空', trigger: 'blur' }
  ],
  main_receiver: [
    { required: true, message: '主送不能为空', trigger: 'blur' }
  ],
  cc_receiver: [
    { required: true, message: '抄送不能为空', trigger: 'blur' }
  ],
  second_policy: [
    { required: true, message: '二政不能为空', trigger: 'blur' }
  ],
  code: [
    { required: true, message: '号不能为空', trigger: 'blur' }
  ],
  public_type: [
    { required: true, message: '请选择公开类型', trigger: 'change' }
  ],
  doc_date: [
    { required: true, message: '请选择日期', trigger: 'change' }
  ],
  attr: [
    { required: true, message: '附件不能为空', trigger: 'blur' }
  ]
}

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      state.loading = true
      fileOtherUpdate(state.ruleForm).then(res => {
        console.log('文件编辑成功', res)
        state.loading = false
        ElMessage({
          type: 'success',
          message: '编辑成功'
        })
        router.push({
          path: '/signFile'
        })
      }).catch(err => {
        state.loading = false
        ElMessage({
          type: 'error',
          message: err.toString()
        })
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 预览
function handleEditPreview () {
  console.log('预览')
  editPreviewTwoRef.value.show(state.ruleForm)
}
</script>
