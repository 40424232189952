<template>
  <div class="edit-preview"
    v-if="state.showEditPreview"
    @click.stop="handleClose">
    <el-scrollbar height="98%" style="margin-top: 1%;">
      <div class="preview-main" @click.stop>
        <div class="row_one">
          <div class="row_one-department">
            <p class="department">
              {{ state.previewData.department }}
            </p> <!-- 来文机关 -->
          </div>
          <div class="row_one-file-code">
            <div class="file_code flex-center">
              {{ state.previewData.doc_date }}
            </div> <!-- 收文日期 -->
          </div>
          <div class="row_one-received-date">
            <div class="received_date flex-center">
              {{ state.previewData.file_code }}
            </div> <!-- 收文文号 -->
          </div>
          <div class="row_one-emergency">
            <div class="emergency flex-center">
              {{ state.previewData.code }}
            </div> <!-- 收文编号 -->
          </div>
        </div>

        <div class="row_two">
          <div class="title">
            {{ state.previewData.title }}
          </div> <!-- 来文名称 -->
        </div>

        <div class="row_three">
          <div class="opinion-wrap">
            <div class="opinion">{{ state.previewData.suggestion }}</div> <!-- 拟办意见 -->
          </div>
          <div class="row_three-right">
            <li class="operator">{{ state.previewData.operator }}</li>
            <li class="operate_date">{{ state.previewData.operate_date }}</li>
          </div>
        </div>

        <div class="icon-close" @click.stop="handleClose">
          <el-icon :size="40"><Close /></el-icon>
        </div>
      </div>
      <div class="preview-image__lazy"
        v-if="state.files && state.files.length">
        <el-image v-for="url in state.files" :key="url" :src="url" />
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup>
import { reactive, defineExpose } from 'vue'
// import { fileView } from '@/api'

const state = reactive({
  showEditPreview: false,
  previewData: null,
  files: null
})

function show (item) {
  state.showEditPreview = true
  state.previewData = item
  // fileView({ id: item.id }).then(res => {
  //   console.log('预览', res)
  //   if (res?.files.length) {
  //     state.files = res.files
  //   } else {
  //     console.log('未查到可预览数据')
  //   }
  // })
}

function handleClose () {
  state.showEditPreview = false
}

defineExpose({
  show
})
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins.scss";
.edit-preview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: .4);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  .preview-main {
    width: 620px;
    height: 876px;
    box-sizing: border-box;
    padding: 122px 70px 0 80px;
    background: url('~@/assets/images/edit_preview_bg5.jpg') no-repeat top left;
    background-size: 100%;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    .row_one {
      width: 100%;
      height: 73px;
      padding-left: 80px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //background: rgba(0,0,0,.6);
      .row_one-department {
        width: 66px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        //background: rgba(0,0,0,.6);
        .department {
          box-sizing: border-box;
          padding: 5px;
          @include lineClamp(5);
        }
      }
      .row_one-file-code {
        width: 60px;
        height: 100%;
        margin-left: 6px;
        box-sizing: border-box;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        //background: rgba(0,0,0,.6);
        .file_code {
          width: 100%;
          height: 41px;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
      }
      .row_one-received-date {
        width: 77px;
        height: 100%;
        box-sizing: border-box;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        //background: rgba(0,0,0,.6);
        .received_date {
          width: 100%;
          height: 48px;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
      }
      .row_one-emergency {
        width: 54px;
        height: 100%;
        box-sizing: border-box;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        //background: rgba(0,0,0,.6);
        .emergency {
          width: 100%;
          height: 43px;
        }
      }
    }
    .row_two {
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      padding: 8px 4px 4px 88px;
      display: flex;
      align-items: center;
      //background: rgba(0,0,0,.6);
      .title {
        line-height: 14px;
        @include lineClamp(3);
      }
    }
    .row_three {
      width: 100%;
      height: 60px;
      box-sizing: border-box;
      padding: 6px 2px 2px 90px;
      margin-top: 281px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      //background: rgba(0,0,0,.6);
      .opinion-wrap {
        width: 470px;
        height: 100%;
        display: flex;
        align-items: center;
        .opinion {
          line-height: 16px;
          @include lineClamp(3);
        }
      }
      .row_three-right {
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .operator {
          width: 100%;
        }
        .operate_date {
          width: 100%;
        }
      }
    }
    .icon-close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 100;
      cursor: pointer;
    }
  }
  .preview-image__lazy {
    width: 620px;
  }
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
